import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import HomeService from '../../services/HomeService';

export const getHomeData = createAsyncThunk('home/getHomeData', async () => {
  try {
    const res = await HomeService.getHomeData();
    // console.log(res.data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
});

const initialState = {
  loading: false,
  error: '',
  homeObj: null,
  products: [],
};

const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    handleFavorite: (state, { payload }) => {
      state.products = payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getHomeData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getHomeData.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.homeObj = payload;
        state.products = payload.products;
      })
      .addCase(getHomeData.rejected, (state) => {
        state.loading = false;
      });
  },
});
export const { handleFavorite } = homeSlice.actions;
export default homeSlice.reducer;
